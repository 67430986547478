/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'back_arrow': {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    data: '<path pid="0" _fill="#095A56" d="M4.596 0l1.06 1.06-2.735 2.736h6.441v1.5H2.821l2.836 2.836-1.06 1.06L0 4.596 4.596 0z"/>'
  }
})
